<template>
  <div class="card" :class="{'card-full': fullHeight, 'card-bordered': isBordered, 'card-stretch': stretch}">
    <div class="card-inner-group" v-if="isGrouped">
      <slot name="grouped"></slot>
    </div>
    <div class="card-inner" :class="noPadding ? 'p-0' : ''">
      <slot></slot>
    </div>
    <slot name="other"></slot>
  </div>
</template>

<script>
export default {
  props: {
    fullHeight: {
      type: Boolean
    },
    noPadding: Boolean,
    isBordered: Boolean,
    stretch: Boolean,
  },
  setup(props, {slots}){

    let isGrouped = slots.grouped && slots.grouped().length

    return {
      isGrouped,
    }
  }
}
</script>
