<template>
  <div class="nk-block-head nk-block-head-sm">
    <div class="nk-block-head-content">
      <h6>{{$t('Invoice address')}}</h6>
      <p>{{$t('Specify the address for your invoices.')}}</p>
    </div>
  </div>
  <div class="nk-block-content">
    <card>
      <template v-slot:grouped>
        <card-inner class="pt-0 px-0">
          <form-group>
            <b-radio label="My address" v-model="newSettings.address_type" value="my_address" id="inv_my_ad" class="mr-2"></b-radio>
            <b-radio label="Other address" v-model="newSettings.address_type" value="other" id="inv_other_ad"></b-radio>
          </form-group>
          <form-group v-if="newSettings.address_type === 'my_address'" class="text-muted">
            <b class="d-block nk-text-muted">{{ userData.company }}</b>
            {{ userData.first_name +' '+userData.last_name }}<br/>
            {{ userData.post_code }}, {{ userData.city+' '+userData.province }}<br/>
            {{ userData.house_no+', '+userData.street }}
          </form-group>
          <template v-else>
            <form-group>
              <label class="mb-0 overline-title">{{ $t('Company name') }}</label>
              <b-input id="inv_company" v-model="newSettings.address.company_name"/>
            </form-group>
            <form-group>
              <label class="mb-0 overline-title">{{ $t('Address') }}</label>
              <textarea class="form-control" v-model="newSettings.address.address"></textarea>
            </form-group>
          </template>
        </card-inner>
        <card-inner class="px-0">
          <nk-button type="primary" size="md" v-on:click="updateSettings">{{ $t('Save') }}</nk-button>
        </card-inner>
      </template>
    </card>
  </div>
</template>

<script>

import {computed, reactive} from "vue";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BRadio from "@core/components/bp-form/components/BRadio";
import {useStore} from "vuex";
import BInput from "@core/components/bp-form/components/BInput";
import Card from "@core/components/cards/Card";
import CardInner from "@core/components/cards/components/CardInner";
import NkButton from "@core/components/button/NkButton";
export default {
  components: {NkButton, CardInner, Card, BInput, BRadio, FormGroup},
  props:{
    invoiceSettings: {
      type: Object,
      required: true,
    }
  },
  emits: ['onUpdateSettings'],
  setup(props, {emit}){

    const store = useStore()
    let newSettings = reactive({
      address_type: props.invoiceSettings.address.company_name ? 'other' : 'my_address',
      address:{
        company_name: props.invoiceSettings.address.company_name,
        address: props.invoiceSettings.address.address,
      }
    })

    const updateSettings = () => {
      // Pass the object as is as defined in the parent component & passed to this component.
      if(newSettings.address_type === 'my_address'){
        newSettings.address.company_name = newSettings.address.address = '';
      }
      emit('onUpdateSettings', {invoice: {address: newSettings.address}})
    }

    return {
      newSettings,
      updateSettings,
      userData: computed(()=> store.state.advisor_data)
    }

  }
}
</script>
