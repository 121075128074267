<template>
  <page-template>
    <main-block>
      <block-content>
        <card no-padding>
          <page-layout :aside-active="asideActive" @toggle-sidebar="asideStatus">
            <template v-slot:contentWrapper>
              <settings-content-header
                  :title="currentTabTitle.title"
                  :description="currentTabTitle.desc"
                  @toggle-sidebar="asideStatus"></settings-content-header>
              <div class="nk-block">
                <content-user-profile-settings v-if="currentTab === 'p_info'"></content-user-profile-settings>
                <content-security-settings v-if="currentTab === 'security'"></content-security-settings>
                <content-activity-log :class="{'d-none' : currentTab !== 'activity'}"></content-activity-log>
                <content-notification-setting v-if="currentTab === 'notifications'"></content-notification-setting>
                <content-invoice-setting v-if="currentTab === 'invoice'" :invoice-settings="settings.invoice" @on-update-settings="updateSettings"></content-invoice-setting>
              </div>
            </template>
            <template v-slot:aside>
              <settings-sidebar
                  :tabs="tabs"
                  :current-tab="currentTab"
                  @switch-tab="switchTab"></settings-sidebar>
            </template>
          </page-layout>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {loadingController, onIonViewWillEnter} from '@ionic/vue';
import {computed, defineComponent, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import Card from "@core/components/cards/Card";
import PageLayout from "@/views/settings/components/PageLayout";
import SettingsSidebar from "@/views/settings/components/SettingsSidebar";
import SettingsContentHeader from "@/views/settings/components/ContentHeader";
import ContentUserProfileSettings from "@/views/settings/components/ContentUserProfile";
import ContentSecuritySettings from "@/views/settings/components/ContentSecuritySettings";
import ContentActivityLog from "@/views/settings/components/ContentActivityLog";
import {useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import ContentNotificationSetting from "@/views/settings/components/ContentNotificationSetting";
import ContentInvoiceSetting from "@/views/settings/components/ContentInvoiceSetting";
import axios from "@/libs/axios";
import useCommonFunc from "@core/comp-functions/common"
import {useStore} from "vuex";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    ContentInvoiceSetting,
    ContentNotificationSetting,
    ContentActivityLog,
    ContentSecuritySettings,
    ContentUserProfileSettings,
    SettingsContentHeader,
    SettingsSidebar,
    PageLayout,
    Card,
    BlockContent,
    MainBlock,
  },
  setup() {

    const i18n = useI18n()
    const store = useStore()
    const{updateObject} = useCommonFunc()
    let asideActive = ref(false)
    const asideStatus = () => {
      asideActive.value = !asideActive.value
    }
    let settings = reactive({
      invoice:{
        address: {
          company_name: '',
          address: '',
        },
      }
    });

    let route = useRoute()

    let currentTab = ref( (route.params.tab ? route.params.tab : 'p_info') )
    const switchTab = (toTab) => {
      currentTab.value = toTab
      asideActive.value = false
    }

    watch( () => route.params.tab, (n) => {
      currentTab.value = n ? n : 'p_info'
    })

    // title & description should be translated i.e. passed to $t()
    const tabs = {
      p_info: {
        title: 'Personal Information',
        icon: 'ni-user-fill-c',
        desc: 'settings.tabs_description.personal',
      },
      notifications: {
        title: 'Notifications',
        icon: 'ni-bell-fill',
        desc: 'settings.tabs_description.notifications',
      },
      activity: {
        title: 'Account Activity',
        icon: 'ni-activity-round-fill',
        desc: 'settings.tabs_description.activity',
      },
      security: {
        title: 'Security Settings',
        icon: 'ni ni-lock-alt-fill',
        desc: 'settings.tabs_description.security',
      },
      invoice: {
        title: 'Invoice settings',
        icon: 'ni ni-file-docs',
        desc: 'settings.tabs_description.invoice',
        display: store.state.advisor_data.company_id < 1
      }
    }
    const currentTabTitle = computed(()=> tabs[currentTab.value])

    const getSettings = async () => {
      const l = await loadingController.create({message: i18n.t('Please wait...')})
      await l.present()
      axios.get('/api/advisor/settings/get')
          .then( (resp) => {
            if(resp.data.success){
              updateObject(settings, resp.data.data);
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => l.dismiss())
    }
    onIonViewWillEnter(()=>{
      getSettings();
    })

    const updateSettings = async ( s ) => {
      updateObject(settings, s)
      const l = await loadingController.create({message: i18n.t('Please wait...')})
      await l.present()
      axios.post('/api/advisor/settings/set', settings)
          .then( (resp) => {
            if(resp.data.success){
              console.log(resp)
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
      .then(()=> l.dismiss())
    }

    return {
      asideActive,
      asideStatus,
      switchTab,
      tabs,
      currentTab,
      currentTabTitle,
      settings,
      updateSettings,
    }
  }
});

</script>
